import API from "../store/utils/APIUtils";
export const SHOW_LOADER = "SHOW_LOADER";
export const HIDE_LOADER = "HIDE_LOADER";

export const show_Loader = () => ({ type: "SHOW_LOADER" });
export const hide_Loader = () => ({ type: "HIDE_LOADER" });

export const fetchDriverAPIsHelper = async (token) => {
  const response = await API.get(
    `/api/v1/operator/drivers/?isDeleted=false`,

    {
      headers: {
        Authorization: "token " + token,
        "Content-Type": "application/json",
      },
    },
  );
  return response;
};

export const addDriverAPIsHelper = async (payload) => {
  try {
    const response = await API.post(
      `/api/v1/operator/drivers/`,
      {
        firstname: payload?.firstname,
        lastname: payload?.lastname,
        phoneNumber: payload?.phoneNumber,
        email: payload?.email,
        driverTypeId: payload?.driverTypeId,
        dateOfBirth: payload?.dateOfBirth,
        licenseNumber: payload?.licenseNumber,
        addExistingDriver: payload?.addExistingDriver,
      },
      {
        headers: {
          Authorization: "token " + payload.access_token,
          "Content-Type": "application/json",
        },
      },
    );
    return response;
  } catch (error) {
    if (error?.response) {
      return error?.response;
    }
    if (error?.request) {
      return error?.response;
    }
  }
  // deleteDriverAPIsHelper
};

export const pdfLogofDriverAPIsHelper = async (driver_data) => {
  console.log(driver_data?.access_token);
  const response = await API.post(
    `/api/v1/operator/drivers/`,
    driver_data?.payload, // TODO:: what is 'values' ??
    {
      headers: {
        Authorization: "Token " + driver_data?.access_token,
        "Content-Type": "application/json",
      },
    },
  );

  return response; // deleteDriverAPIsHelper
};

export const deleteDriverAPIsHelper = async (payload) => {
  const response = await API.put(
    `/api/v1/operator/delete/${payload.driver_id}/driver/`,
    {
      isDelete: payload.isDelete,
      isInactive: payload.isInactive,
      reason: payload.reason,
    },
    {
      headers: {
        Authorization: "token " + payload.access_token,
        "Content-Type": "application/json",
      },
    },
  );

  return response; // updateDriverAPIsHelper
};

export const updateDriverAPIsHelper = async (payload) => {
  // alert(JSON.stringify(driverProfileData))
  const response = await API.put(
    `/api/v1/operator/update/${payload.driverId}/driver/`,
    {
      firstname: payload.firstname,
      lastname: payload.lastname,
      phoneNumber: payload.phoneNumber,
      dateOfBirth: payload.dateOfBirth,
      driverTypeId: payload.driverTypeId,
      email: payload.email,
      licenseNumber: payload.licenseNumber,
    },
    {
      headers: {
        Authorization: "token " + payload.access_token, // driverProfileData.access_token
        "Content-Type": "application/json",
      },
    },
  );

  return response; // updateDriverAPIsHelper
};
export const fetchPendingDriverInvitationAPIsHelper = async (token) => {
  const response = await API.get(`/api/v1/operator/pending/drivers/`, {
    headers: {
      Authorization: "Token " + token,
      "Content-Type": "application/json",
    },
  });
  // console.log("fetchPendingDriverInvitationAPIsHelper",response)
  return response;
};

export const acceptDriverInvitationAPIsHelper = async (payload) => {
  console.log("accept", payload);
  const response = await API.put(
    `/api/v1/operator/accept/driver/${payload.id}/invite/`,
    {},
    {
      headers: {
        Authorization: `token ${payload.token}`,
        "Content-Type": "application/json",
      },
    },
  );

  return response;
};

export const rejectDriverInvitationAPIsHelper = async (payload) => {
  console.log("reject", payload);
  const response = await API.put(
    `/api/v1/operator/reject/driver/${payload.id}/invite/`,
    {},
    {
      headers: {
        Authorization: `token ${payload.token}`,
        // Authorization: 'token eyJ0eXAiOiJKV1QiLCJhbGciOiJIUzI1NiJ9.eyJkYXRhIjoiSWpNek1pSToxb3k1amw6NEYxcmtTTXZaLTUwQ29tekJJU2trMEVqZ0w3TnRGdWZTeEFHXzJlaEZ6SSIsImV4cCI6MTY2OTM1Nzc2OSwiaWF0IjoxNjY5MjcxMzY5fQ.kOiUtVHPmNGGouOgpJeysJerua5WE9GcVUwE1vc5Fs4',
        "Content-Type": "application/json",
        Accept: "application/json",
      },
    },
  );
  console.log("reject", response);

  return response;
};

export const fetchRejectedDriverAPIsHelper = async (token) => {
  console.log("/rejected/drivers/");
  const response = await API.get(`/api/v1/operator/rejected/drivers/`, {
    headers: {
      Authorization: "Token " + token,
      "Content-Type": "application/json",
    },
  });
  // console.log("fetchRejectedDriverAPIsHelper",response)
  return response;
};

export const fetchSingleDriverLogsAPIsHelper = async (payload) => {
  const params = new URLSearchParams();
  if (payload?.fromDate && payload?.toDate) {
    params.append("fromDate", payload?.fromDate);
    params.append("toDate", payload?.toDate);
  }

  if (payload.page) {
    params.append("page", payload?.page);
  }
  const response = await API.get(
    `/api/v1/operator/${
      payload?.id
    }/driver/cumulative/logs/?${params.toString()}`,
    {
      headers: {
        Authorization: `token ${payload.token}`,
        "Content-Type": "application/json",
      },
    }
  );
  return response;
};

export const fetchSingleDriverLogsDataAPIsHelper = async (payload) => {
  const response = await API.get(
    `/api/v1/operator/${payload.driverid}/driver/logs/${payload.logid}`,
    {
      headers: {
        Authorization: `token ${payload.token}`,
        "Content-Type": "application/json",
      },
    },
  );
  console.log("fetchRejectedDriverAPIsHelper", response);
  return response;
};

export const verifyDriverEmailAPIHelper = async (emailVerificationID) => {
  const response = await API.get(
    `/api/v1/driver/verify/email/${emailVerificationID}`,
  );

  return response;
};
export const deleteDriverAccountHelper = async (driverId) => {
  const response = await API.post(`/api/v1/driver/delete/account/${driverId}/`);

  return response;
};

