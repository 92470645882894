import { Card, Col, DatePicker, Modal, Select } from "antd";
import { Spin, message } from "antd";
import React, { useEffect, useState, useRef } from "react";
import { Row } from "antd";
import {
  fetchSingelDriverLogData,
  fetchSingelDriverLogs,
  filtrDateForLog,
  setPDFReportLink,
} from "../store/reducers/driverReducer";
import { useDispatch, useSelector } from "react-redux";
import { SearchOutlined, ArrowLeftOutlined } from "@ant-design/icons";
import { Button, Input, Space, Table } from "antd";
import Highlighter from "react-highlight-words";
import { useNavigate } from "react-router-dom";
import Driverdetails from "../components/driverdetails";
import moment from "moment";
import { CSVLink } from "react-csv";

const { Meta } = Card;

const cardStyle = {
  width: 180,
  height: 100,
};
const ViolationcardStyle = {
  width: 180,
  height: 138,
};

const TopcardStyle = {
  marginRight: 10,
};

const { RangePicker } = DatePicker;

const DriverlogHistory = (props) => {
  const { Option } = Select;
  const navigate = useNavigate();
  const [loadingSpinner, setLoadingSpinner] = useState(false);
  const [messageApi, contextHolder] = message.useMessage();
  const [selectedDays, setSelectedDays] = useState("7");
  const [dateRange, setDateRange] = useState(null);
  const [showDateRange, setShowDateRange] = useState(false);
  const access_token = useSelector((state) => state?.persist?.auth.token);
  const { Column, ColumnGroup } = Table;
  const dispatch = useDispatch();

  const profile = useSelector(
    (state) => state?.persist?.operator?.operatorProfile?.operator
  );

  const [isAddDriverModalOpen, setAddDriverModal] = useState();
  const closeDriverModal = () => {
    setAddDriverModal(false);
  };

  const driverData = useSelector(
    (state) => state?.persist?.driver?.singedriverlogs?.data
  );

  const cumulativeLogs = useSelector(
    (state) => state?.persist?.driver?.singedriverlogs?.data?.cumulativeLogs
  );

  const [searchText, setSearchText] = useState("");
  const [searchedColumn, setSearchedColumn] = useState("");
  const searchInput = useRef(null);
  let activeDriverData = useSelector(
    (state) => state?.persist?.vehicle?.activeDriver
  );

  useEffect(() => {
    if (!access_token) return;

    let fromDate, toDate;

    if (selectedDays === "date-range" && dateRange?.length === 2) {
      fromDate = moment(dateRange[0]).format("YYYY-MM-DD");
      toDate = moment(dateRange[1]).format("YYYY-MM-DD");
    } else {
      fromDate = moment().subtract(selectedDays, "days").format("YYYY-MM-DD");
      toDate = moment().format("YYYY-MM-DD");
    }

    if (selectedDays !== "date-range" || dateRange) {
      setLoadingSpinner(true);
      dispatch(
        fetchSingelDriverLogs({
          token: access_token,
          id: props.Id,
          fromDate,
          toDate,
          page: 100,
        })
      ).then((res) => {
        setLoadingSpinner(false);
        if (!res?.payload?.data?.success) {
          message.warning(
            res?.payload?.data?.error ||
              res?.payload?.data?.message ||
              "Error in fetching driver logs"
          );
        }
      });
    }
  }, [access_token, selectedDays, dateRange, dispatch]);

  const handleSearch = (selectedKeys, confirm, dataIndex) => {
    confirm();
    setSearchText(selectedKeys[0]);
    setSearchedColumn(dataIndex);
  };
  const handleReset = (clearFilters) => {
    clearFilters();
    setSearchText("");
  };
  const getColumnSearchProps = (dataIndex) => ({
    filterDropdown: ({
      setSelectedKeys,
      selectedKeys,
      confirm,
      clearFilters,
      close,
    }) => (
      <div
        style={{
          padding: 8,
        }}
        onKeyDown={(e) => e.stopPropagation()}
      >
        <Input
          ref={searchInput}
          placeholder={`Search Date`}
          value={selectedKeys[0]}
          onChange={(e) =>
            setSelectedKeys(e.target.value ? [e.target.value] : [])
          }
          onPressEnter={() => handleSearch(selectedKeys, confirm, dataIndex)}
          style={{
            marginBottom: 8,
            display: "block",
          }}
        />
        <Space>
          <Button
            type="primary"
            onClick={() => handleSearch(selectedKeys, confirm, dataIndex)}
            icon={<SearchOutlined />}
            size="small"
            style={{
              width: 90,
            }}
          >
            SEARCH
          </Button>
          <Button
            onClick={() => clearFilters && handleReset(clearFilters)}
            size="small"
            style={{
              width: 90,
            }}
          >
            RESET
          </Button>
          <Button
            type="link"
            size="small"
            onClick={() => {
              confirm({
                closeDropdown: false,
              });
              setSearchText(selectedKeys[0]);
              setSearchedColumn(dataIndex);
            }}
          >
            FILTER
          </Button>
          <Button
            type="link"
            size="small"
            onClick={() => {
              close();
            }}
          >
            CLOSE
          </Button>
        </Space>
      </div>
    ),
    filterIcon: (filtered) => (
      <SearchOutlined
        style={{
          color: filtered ? "#1890ff" : undefined,
        }}
      />
    ),
    onFilter: (value, record) =>
      record[dataIndex].toString().toLowerCase().includes(value.toLowerCase()),
    onFilterDropdownOpenChange: (visible) => {
      if (visible) {
        setTimeout(() => searchInput.current?.select(), 100);
      }
    },
    render: (text) =>
      searchedColumn === dataIndex ? (
        <Highlighter
          highlightStyle={{
            backgroundColor: "#ffc069",
            padding: 0,
          }}
          searchWords={[searchText]}
          autoEscape
          textToHighlight={text ? text.toString() : ""}
        />
      ) : (
        text
      ),
  });
  let activeDriver = [];
  const sortedActiveDriver = activeDriver.sort(
    (a, b) => new Date(b.createdAt) - new Date(a.createdAt)
  );

  console.log(sortedActiveDriver, "sortedActiveDriver");
  const todaysdate = new Date().toLocaleString();

  const headers = [
    { label: "Date", key: "createdAt" },
    { label: "Driver Name", key: "name" },
    { label: "Work Done In Day (hh:mm)", key: "workdone" },
    { label: "Work Remaining In Day (hh:mm)", key: "workremaining" },
    { label: "Rest Taken In Day (hh:mm)", key: "resttaken" },
    { label: "Report", key: "link" },
  ];

  let singleDriverlog = useSelector(
    (state) => state?.persist?.drivers?.singedriverlogs
  );

  if (driverData) {
    driverData?.cumulativeLogs?.map((driverItem, index) => {
      activeDriver.push({
        createdAt: moment(driverItem?.createdAt).format("DD-MMM-YY"),
        driverId: driverItem?.driver?.id,
        id: index + 1,
        data: driverItem,
        restInDay: driverItem.restInDay,
        restInPeriod: driverItem.restInPeriod,
        workDoneInDay: driverItem?.workDoneInDay,
        workDoneInPeriod: driverItem?.workDoneInPeriod,
        workRemainingInDay: driverItem?.workRemainingInDay,
        workRemainingInPeriod: driverItem?.workRemainingInPeriod,
      });
    });
  }

  const viewDetailLogs = async (data) => {
    const payload = {
      driverid: props.Id,
      logid: data?.data?.id,
      token: access_token,
    };

    try {
      await Promise.all([
        dispatch(setPDFReportLink(data?.data?.report)),
        dispatch(
          filtrDateForLog(moment(data?.data?.createdAt).format("DD-MM-YY"))
        ),
        dispatch(fetchSingelDriverLogData(payload)),
      ]);

      navigate(`/app/drivers/driver/details/${data?.id}`);
    } catch (error) {
      console.error("Error while fetching log details:", error);
    }
  };

  const columns = [
    {
      title: "Date",
      dataIndex: "createdAt",
      key: "createdAt",
      width: 100,
      align: "center",
      onFilter: (value, record) => record.name.indexOf(value) === 0,
      ...getColumnSearchProps("createdAt"),
    },
    {
      title: "Work Remaining In Day (hh:mm)",
      align: "center",
      render: (record) => (
        <Space size="middle">
          {record?.workRemainingInDay
            ? (record?.workRemainingInDay?.hours < 10
                ? "0" + record?.workRemainingInDay?.hours
                : record?.workRemainingInDay?.hours) +
              ":" +
              (record?.workRemainingInDay?.minutes < 10
                ? "0" + record?.workRemainingInDay?.minutes
                : record?.workRemainingInDay?.minutes)
            : "00:00"}
        </Space>
      ),
    },
    {
      title: "Work Done In Day (hh:mm)",
      align: "center",
      render: (record) => (
        <Space size="middle">
          {record?.workDoneInDay
            ? (record?.workDoneInDay?.hours < 10
                ? "0" + record?.workDoneInDay?.hours
                : record?.workDoneInDay?.hours) +
              ":" +
              (record?.workDoneInDay?.minutes < 10
                ? "0" + record?.workDoneInDay?.minutes
                : record?.workDoneInDay?.minutes)
            : "00:00"}
        </Space>
      ),
    },
    {
      title: "Rest In Day (hh:mm)",
      align: "center",
      render: (record) => (
        <Space size="middle">
          {(record.restInDay
            ? record.restInDay.hours < 10
              ? "0" + record.restInDay.hours
              : record.restInDay.hours
            : "00") +
            ":" +
            (record.restInDay.minutes
              ? record.restInDay.minutes < 10
                ? "0" + record.restInDay.minutes
                : record.restInDay.minutes
              : "00")}
        </Space>
      ),
    },
    {
      title: "PDF Logs",
      key: "operation",
      width: 90,
      align: "center",
      render: (text, record) => (
        <a href={record?.data?.report} target={"_blank"}>
          {"Pdf Link"}
        </a>
      ),
    },

    {
      title: "View Logs",
      key: "operation",
      align: "center",
      width: 90,
      render: (_, record) => (
        // dataSource.length >= 1 ? (
        <a
          onClick={(e) => {
            viewDetailLogs(record);
          }}
        >
          View
        </a>
      ),
      // ) : null,
    },
  ];

  const backToPrev = () => {
    navigate(`/app/drivers`);
  };

  return (
    <Spin spinning={loadingSpinner} size="large">
      {contextHolder}
      <div>
        <Modal
          open={isAddDriverModalOpen}
          width={300}
          bodyStyle={{ padding: 0 }}
          onCancel={closeDriverModal}
          footer={false}
        >
          <Driverdetails activeDriverData={activeDriverData} />
        </Modal>
        <Row
          gutter={{
            xs: 8,
            sm: 16,
            md: 24,
            lg: 12,
          }}
          style={{
            display: "flex",
            justifyContent: "space-between",
            alignItems: "center",
          }}
        >
          <Col>
            <Row>
              {" "}
              <ArrowLeftOutlined
                style={{
                  width: 60,
                  fontSize: 20,
                  marginTop: 20,
                  marginBottom: 20,
                  paddingRight: 10,
                }}
                onClick={() => backToPrev()}
              />
              <h2
                onClick={() => setAddDriverModal(true)}
                style={{ marginTop: 10, cursor: "pointer", fontWeight: "bold" }}
              >
                {activeDriverData?.name}
              </h2>
            </Row>
          </Col>
          <Col>
            <Row>
              {profile?.isAdmin && (
                <Button
                  style={{
                    marginLeft: 20,
                    alignSelf: "flex-end",
                  }}
                  type="primary"
                >
                  <CSVLink
                    data={sortedActiveDriver.map((item) => ({
                      createdAt: item.createdAt,
                      name: item.data.driver.name,
                      workdone: item.data.workDoneInDay.hours
                        ? `${item.data.workDoneInDay.hours}:${item.data.workDoneInDay.minutes}`
                        : "00:00",
                      workremaining: item.data.workRemainingInDay.hours
                        ? `${item.data.workRemainingInDay.hours}:${item.data.workRemainingInDay.minutes}`
                        : "00:00",
                      resttaken: item.data.restInDay.hours
                        ? `${item.data.restInDay.hours}:${item.data.restInDay.minutes}`
                        : "00:00",
                      link: ` ${item.data.report}`,
                    }))}
                    headers={headers}
                    filename={`${activeDriverData?.name}_Working_hours(${todaysdate}).csv`}
                    target="_blank"
                  >
                    EXPORT TABLE
                  </CSVLink>
                </Button>
              )}
            </Row>
          </Col>
        </Row>
        <Row
          gutter={[16, 16]}
          style={{
            marginBottom: 20,
            // padding: 2,
            justifyContent: "space-between",
            width: "100%",
          }}
        >
          <Col span={24}>
            <h3>Cumulative Week</h3>
            <Row
              gutter={[16, 16]}
              style={{ display: "flex", flexWrap: "wrap" }}
            >
              {/* Work Card */}
              <Col xs={24} sm={12} md={8}>
                <Card
                  title="Work"
                  style={{
                    ...TopcardStyle,
                    width: "100%",
                    display: "flex",
                    flexDirection: "column",
                  }}
                  bodyStyle={{
                    flex: 1,
                    display: "flex",
                    flexDirection: "column",
                  }}
                  headStyle={{ backgroundColor: "#0acf83" }}
                  type="inner"
                  size="small"
                >
                  <Row gutter={[8, 8]} style={{ display: "flex", flex: 1 }}>
                    <Col span={12} style={{ display: "flex" }}>
                      <Card
                        title="Total (hh:mm:ss)"
                        style={{ ...cardStyle, flex: 1 }}
                        type="inner"
                        size="small"
                      >
                        <div>
                          {driverData?.summary?.data
                            ? driverData?.summary?.data?.CumulativeDriveTime
                            : "00:00:00"}
                        </div>
                      </Card>
                    </Col>
                    <Col span={12} style={{ display: "flex" }}>
                      <Card
                        title="Remaining (hh:mm:ss)"
                        style={{ ...cardStyle, flex: 1 }}
                        type="inner"
                        size="small"
                      >
                        <div>
                          {driverData?.summary?.data
                            ? driverData?.summary?.data?.TotalRemainderWorkhours
                            : "00:00:00"}
                        </div>
                      </Card>
                    </Col>
                  </Row>
                </Card>
              </Col>

              {/* Rest Card */}
              <Col xs={24} sm={12} md={8}>
                <Card
                  title="Rest"
                  style={{
                    ...TopcardStyle,
                    width: "100%",
                    display: "flex",
                    flexDirection: "column",
                  }}
                  bodyStyle={{
                    flex: 1,
                    display: "flex",
                    flexDirection: "column",
                  }}
                  headStyle={{ backgroundColor: "#4facf7" }}
                  type="inner"
                  size="small"
                >
                  <Row style={{ flex: 1, display: "flex" }}>
                    <Col span={24} style={{ display: "flex" }}>
                      <Card
                        title="Total (hh:mm:ss)"
                        style={{ ...cardStyle, flex: 1 }}
                        type="inner"
                        size="small"
                      >
                        {driverData?.summary?.data
                          ? driverData?.summary?.data?.CumulativeRestTime
                          : "00:00:00"}
                      </Card>
                    </Col>
                  </Row>
                </Card>
              </Col>

              {/* Violations Card */}
              <Col xs={24} sm={12} md={8}>
                <Card
                  title="Violations"
                  type="inner"
                  size="small"
                  headStyle={{ backgroundColor: "#ff6700" }}
                  style={{
                    ...ViolationcardStyle,
                    width: "100%",
                    display: "flex",
                    flexDirection: "column",
                  }}
                  bodyStyle={{
                    flex: 1,
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "center",
                  }}
                >
                  <div style={{ paddingTop: 20 }}>{"0"}</div>
                </Card>
              </Col>
            </Row>
          </Col>
        </Row>

        <Row gutter={[16, 16]} align="middle" justify="end">
          <Col>
            <Space direction="horizontal">
              <span style={{ fontWeight: 500 }}>Filter logs by date:</span>
              <Select
                style={{ width: 150 }}
                value={selectedDays}
                onChange={(value) => {
                  setSelectedDays(value);
                  if (value !== "date-range") {
                    setDateRange(null);
                  }
                }}
              >
                <Option value="7">Last 7 Days</Option>
                <Option value="14">Last 14 Days</Option>
                <Option value="30">Last 30 Days</Option>
                <Option value="90">Last 90 Days</Option>
                <Option value="date-range">Select Date Range</Option>
              </Select>
              {selectedDays === "date-range" && (
                <RangePicker
                  value={dateRange}
                  onChange={(dates) => setDateRange(dates)}
                  disabledDate={(current) =>
                    current && current > moment().endOf("day")
                  }
                />
              )}
            </Space>
          </Col>
        </Row>

        <Row style={{ marginTop: 20 }}>
          <Col span={24}>
            <Table
              columns={columns}
              dataSource={activeDriver.sort(
                (a, b) => new Date(b.createdAt) - new Date(a.createdAt)
              )}
              bordered
              size="middle"
              scroll={{ x: "100%", y: 240 }}
              pagination={{ pageSize: 5 }}
            />
          </Col>
        </Row>
      </div>
    </Spin>
  );
};

export default DriverlogHistory;
